import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import imgBackground from "../../assets/images/sertifikat.png";
import imgBackgroundBB from "../../assets/images/sertifikat-bb.png";
import dataLenny from "../../assets/images/barcode-lenny.png";

const styles = StyleSheet.create({
  pageBackground: {
    position: "relative",
    display: "block",
    height: "100%",
    width: "100%",
  },
  title: {
    top: "130px",
    position: "absolute",
    left: "0px",
    right: "0px",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "center",
  },
  content: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginLeft: 60,
    marginRight: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    fontFamily: "Helvetica",
  },
  katsim: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginLeft: 20,
    marginRight: 10,
    textAlign: "center",
  },
  barcodePsi: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginLeft: 300,
    marginRight: 10,
    textAlign: "center",
  },
  table: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginLeft: 60,
    marginRight: 60,
    paddingBottom: 4,
  },
  rowTable: {
    flexDirection: "row",
    borderBottom: 1,
  },
  rowDataTable: {
    flexDirection: "row",
  },
  colTable: {
    paddingBottom: 2,
    marginRight: 40,
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
  },
  colDataTable: {
    paddingBottom: 2,
    marginRight: 40,
    marginTop: 4,
    fontFamily: "Helvetica",
    fontSize: 10,
  },
  dataBarcode: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginLeft: 10,
    marginRight: 300,
    textAlign: "center",
  },
});

const H_CONTENT_TOP = 165;
const H_X = 18;

const Dockpdf = ({ data }) => (
  <Document>
    <Page size="A4" orientation="portrait" wrap={false}>
      <View>
        <Image
          src={data.statusBayar === "Y" ? imgBackground : imgBackgroundBB}
          style={styles.pageBackground}
        />
      </View>
      <View style={styles.title}>
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 18 }}>
          HASIL TES PSIKOLOGI
        </Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>NIK</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11 }}>{data.nik}</Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP + H_X * 1}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>Nama Lengkap</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11, fontFamily: "Helvetica-Bold" }}>
          {data.namaLengkap}
        </Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP + H_X * 2}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>Usia (Lulusan)</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11 }}>
          {data.usia} ({data.pdTerakhir})
        </Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP + H_X * 3}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>Jenis Kelamin</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11 }}>{data.jenKel}</Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP + H_X * 4}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>Nomor HP</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11 }}>{data.noHp}</Text>
      </View>
      <View style={{ top: `${H_CONTENT_TOP + H_X * 5}px`, ...styles.content }}>
        <Text style={{ fontSize: 11, flexBasis: "150px" }}>Alamat</Text>
        <Text style={{ fontSize: 11, marginRight: 4 }}>:</Text>
        <Text style={{ fontSize: 11 }}>{data.alamat}</Text>
      </View>

      <View style={{ top: `${H_CONTENT_TOP + H_X * 7}px`, ...styles.katsim }}>
        <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 12 }}>
          REKOMENDASI
        </Text>
        <Text
          style={{ fontFamily: "Helvetica-Bold", fontSize: 16, marginTop: 8 }}
        >
          SIM{" "}
          {data.katSim === "AC"
            ? "A dan C"
            : data.katSim === "BC"
            ? "B dan C"
            : data.katSim}{" "}
          {data.jenSim}
        </Text>

        {data.hasilTes === "MS" ? (
          <Text
            style={{
              fontFamily: "Helvetica",
              fontSize: 14,
              marginTop: 5,
              color: "green",
            }}
          >
            MEMENUHI SYARAT
          </Text>
        ) : (
          <Text
            style={{
              fontFamily: "Helvetica",
              fontSize: 14,
              marginTop: 5,
              color: "red",
            }}
          >
            BELUM MEMENUHI SYARAT
          </Text>
        )}
      </View>
      <View
        style={{
          top: `${H_CONTENT_TOP + H_X * 11}px`,
          marginTop: 5,
          ...styles.table,
        }}
      >
        <View style={styles.rowTable}>
          <View style={styles.colTable}>
            <Text>NO</Text>
          </View>
          <View style={{ flexGrow: 1, ...styles.colTable }}>
            <Text>SCORE</Text>
          </View>
          <View style={styles.colTable}>
            <Text>KATEGORI</Text>
          </View>
        </View>
        {data.hitungNilai.length > 0 &&
          data.hitungNilai.map((row, index) => (
            <View key={index} style={styles.rowDataTable}>
              <View style={styles.colDataTable}>
                <Text>{index + 1}</Text>
              </View>
              <View style={{ flexGrow: 1, ...styles.colDataTable }}>
                <Text>{row.namaScoring}</Text>
              </View>
              <View style={styles.colDataTable}>
                {row.namaKategori === "RENDAH" ||
                row.namaKategori === "KURANG" ? (
                  <Text style={{ color: "red" }}>{row.namaKategori}</Text>
                ) : (
                  <Text style={{ color: "green" }}>{row.namaKategori}</Text>
                )}
              </View>
            </View>
          ))}
      </View>

      {/* data barcode */}
      <View
        style={{
          top: `${H_CONTENT_TOP + H_X * 23}px`,
          alignItems: "center",
          ...styles.dataBarcode,
        }}
      >
        <Image
          src={data.qrcodelink}
          style={{
            width: 90,
            height: 90,
            border: 1,
            padding: 4,
            marginTop: 12,
          }}
        />
      </View>

      {/* data barcode PSIKOLOGI */}
      <View
        style={{
          top: `${H_CONTENT_TOP + H_X * 23}px`,
          alignItems: "center",
          ...styles.barcodePsi,
        }}
      >
        <Image
          src={dataLenny}
          style={{
            width: 80,
            height: 80,
            border: 1,
            padding: 4,
            marginTop: 18,
          }}
        />
      </View>
      <View
        style={{ top: `${H_CONTENT_TOP + H_X * 28}px`, ...styles.barcodePsi }}
      >
        <Text
          style={{
            fontFamily: "Helvetica",
            fontSize: 12,
            marginTop: 10,
          }}
        >
          PSIKOLOG
        </Text>
        <Text
          style={{
            fontFamily: "Helvetica-Bold",
            fontSize: 12,
            marginTop: 4,
          }}
        >
          {/* LENNY UTAMA AFRIYENTI, S.Psi, M.Psi, Psikolog */}
          {data.psikolog}
        </Text>
        <Text
          style={{
            fontFamily: "Helvetica",
            fontSize: 12,
            marginTop: 4,
          }}
        >
          {/* SIPP. 0555-19-2-2 */}
          {data.psikologSipp}
        </Text>
      </View>
      <View
        style={{
          top: `${H_CONTENT_TOP + H_X * 34}px`,
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          ...styles.katsim,
        }}
      >
        <Text
          style={{
            fontFamily: "Helvetica",
            fontSize: 12,
            marginTop: 4,
            textAlign: "right",
            marginRight: 20,
            color: "#FFFFFF",
            width: "210px",
          }}
        >
          Hasil tes berlaku selama 6 bulan sampai dengan: {data.tglExpired}
        </Text>
      </View>
    </Page>
  </Document>
);

export default Dockpdf;
